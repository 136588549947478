"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStripeElements = exports.getStripe = void 0;
const common_ui_1 = require("../../../common-ui");
const stripe_js_1 = require("@stripe/stripe-js");
let stripePromise;
function getStripe() {
    if (!stripePromise) {
        const stripePublicKey = (0, common_ui_1.getStripePublicKey)();
        stripePromise = (0, stripe_js_1.loadStripe)(stripePublicKey);
    }
    return stripePromise;
}
exports.getStripe = getStripe;
async function getStripeElements() {
    let stripe = await getStripe();
    return stripe.elements();
}
exports.getStripeElements = getStripeElements;
