"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var common_ui_1 = require("@rose/common-ui");

var vertragSubscriptions_1 = require("@/components/columns/settings/vertragSubscriptions");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    subscriptions: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      "default": false
    }
  },
  data: function data() {
    return {
      localeText: common_ui_1.german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: {},
      rowClassRules: {},
      pendingZuordnungen: []
    };
  },
  watch: {
    loading: function loading(val) {
      if (val) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    }
  },
  beforeMount: function beforeMount() {
    this.columnDefs = (0, vertragSubscriptions_1.settingsSubscriptionsColumnsDefs)();
    this.gridOptions = {
      rowHeight: 32,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };
  },
  methods: {
    onGridReady: function onGridReady(event) {
      this.gridApi = event.api;
      this.gridColumnApi = event.columnApi;
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    }
  }
});