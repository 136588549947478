"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsSubscriptionsColumnsDefs = void 0;
const common_ui_1 = require("@rose/common-ui");
const lodash_1 = require("lodash");
function settingsSubscriptionsColumnsDefs() {
    return [
        {
            headerName: 'Produkt',
            valueGetter: params => { var _a; return (0, lodash_1.map)((_a = params.data) === null || _a === void 0 ? void 0 : _a.items, product => product.priceNickname || product.price).join(', '); },
            sort: 'asc',
            sortable: true,
            resizable: true,
            flex: 1,
        },
        {
            headerName: 'Start',
            field: 'start',
            valueFormatter: common_ui_1.dateFormatter,
            sortable: true,
            resizable: true,
            width: 200,
        },
        {
            headerName: 'Nächste Rechnung',
            field: 'periodend',
            valueFormatter: common_ui_1.dateFormatter,
            sortable: true,
            resizable: true,
            width: 300,
        },
    ];
}
exports.settingsSubscriptionsColumnsDefs = settingsSubscriptionsColumnsDefs;
