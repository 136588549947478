"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsInvoiceColumnsDefs = void 0;
const common_ui_1 = require("@rose/common-ui");
function settingsInvoiceColumnsDefs() {
    return [
        {
            headerName: 'Rechnungen',
            field: 'number',
            sort: 'desc',
            sortable: true,
            flex: 1,
            resizable: true,
        },
        {
            headerName: 'Datum',
            field: 'created',
            valueFormatter: common_ui_1.dateFormatter,
            sortable: true,
            resizable: true,
        },
        {
            headerName: 'Betrag',
            field: 'amount',
            valueFormatter: common_ui_1.euroFormatter,
            sortable: true,
            resizable: true,
        },
        {
            headerName: 'Download',
            cellRenderer: 'DownloadCell',
            cellStyle: { justifyContent: 'center' },
            width: 100,
        },
    ];
}
exports.settingsInvoiceColumnsDefs = settingsInvoiceColumnsDefs;
